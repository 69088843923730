import { useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'api';
import { setIsSiteUnavailable } from 'ducks/pageStatus/pageStatusSlice';
import { isFeatureEnabled } from 'lib/featuresHelper';
import { useAppDispatch } from '../reduxHooks';
import { useUserAccess } from '../useProjectResources';

export type FeatureFlag =
  | 'advanced_code_editor'
  | 'call_handoff'
  | 'function_calls'
  | 'genai_voice'
  | 'import-kb-spreadsheet'
  | 'jobs_to_be_done'
  | 'jupiter_analytics'
  | 'jupiter_safety_analytics'
  | 'jupiter_user_management'
  | 'mask_conversation_pii'
  | 'pronunciations'
  | 'read_only_view'
  | 'secrets_manager'
  | 'select_language'
  | 'sms_templates'
  | 'stripe-payments'
  | 'telephony'
  | 'topic_citations'
  | 'voice_configs'
  | 'content_filters';

export const useIsFeatureOn = () => {
  const dispatch = useAppDispatch();
  const { isPlatformTeamUser } = useUserAccess();

  const queryClient = useQueryClient();

  const {
    data: features,
    isLoading: isLoadingFeatures,
    isError: didFeaturesError,
    isStale,
    refetch,
  } = useQuery(['features'], api.getFeatures, {
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 60000,
    onSuccess: (features) => {
      queryClient.setQueryData(['features'], features);
      const isUnderMaintenance = isFeatureEnabled(features, 'site_maintenance');
      dispatch(setIsSiteUnavailable(isUnderMaintenance && !isPlatformTeamUser));
    },
  });

  useEffect(() => {
    if (isStale) {
      refetch().catch((e) =>
        console.error('Error occurred. Could not refetch query', e),
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isFeatureOn = (featureName: FeatureFlag) => {
    return isFeatureEnabled(features ?? [], featureName);
  };

  return {
    features,
    isLoadingFeatures,
    didFeaturesError,
    isFeatureOn,
  };
};
