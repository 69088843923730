import { ReactElement } from 'react';

import { datadogLogs, StatusType } from '@datadog/browser-logs';

import { AlertMessageType } from '@polyai/common/hooks/useBaseAlert';
import { getNodeText } from '@polyai/common/lib/miscHelper';

import { store } from 'reduxStore';
import { LOG_ERROR_CATEGORY, LOGGER_TYPE } from '../api/types';
import { ALERT_LOGGER_TYPE } from '../hooks/useToast';

enum ERROR_CATEGORY {
  NORMAL = 20,
  USER = 40,
  SYSTEM = 50,
  UI = 55,
}

const checkE = (e: string, E: ERROR_CATEGORY) => e.startsWith(E.toString());

export const getErrorCategory = (errorID?: string, statusCode?: number) => {
  if (statusCode === 403) {
    return LOG_ERROR_CATEGORY.NORMAL;
  }
  if (statusCode === 404) {
    return LOG_ERROR_CATEGORY.UI;
  }
  if (!errorID) {
    return LOG_ERROR_CATEGORY.UNDEFINED;
  }

  if (checkE(errorID, ERROR_CATEGORY.NORMAL)) {
    return LOG_ERROR_CATEGORY.NORMAL;
  }
  if (checkE(errorID, ERROR_CATEGORY.USER)) {
    return LOG_ERROR_CATEGORY.USER;
  }
  if (checkE(errorID, ERROR_CATEGORY.SYSTEM)) {
    return LOG_ERROR_CATEGORY.SYSTEM;
  }
  if (checkE(errorID, ERROR_CATEGORY.UI)) {
    return LOG_ERROR_CATEGORY.UI;
  }

  return LOG_ERROR_CATEGORY.UNDEFINED;
};

interface Message {
  props: {
    title: string;
    children: string | Message | string[];
  };
}

/* Get the text from an alert message component.*/
export const getAlertMessage = (
  message: ReactElement<AlertMessageType>,
): { innerText: string; title: string } => {
  const { props } = message.valueOf() as Message;

  return {
    innerText: getNodeText(message),
    title: props.title,
  };
};

export const logToDataDog = (
  loggerType: ALERT_LOGGER_TYPE | LOGGER_TYPE,
  statusType: StatusType,
  message: string,
  body: Record<string, any>,
) => {
  const ddLogger = datadogLogs.getLogger(loggerType);
  if (!!ddLogger) {
    // We always want to log these things
    const project_id = store.getState().project.id || '*';
    const account_id = store.getState().account.id || '*';
    const user = store.getState().auth.user || {};

    const logBody = {
      project_id,
      account_id,
      user_email: user.email || 'Unknown user',
      ...body,
    };

    switch (statusType) {
      case 'error':
        return ddLogger.error(message, logBody);
      case 'warn':
        return ddLogger.warn(message, logBody);
      case 'debug':
        return ddLogger.debug(message, logBody);
      case 'info':
      default:
        return ddLogger.info(message, logBody);
    }
  }
};

export type ActionUserFlow =
  | 'About'
  | 'Account'
  | 'Alert'
  | 'Analytics'
  | 'Assistant'
  | 'Assistants'
  | 'Assistant Chat'
  | 'Billing'
  | 'Conversations'
  | 'Conversation Review'
  | 'Create Assistant'
  | 'Draft'
  | 'Environments'
  | 'Flow'
  | 'Flows'
  | 'Functions'
  | 'Function'
  | 'Start Function'
  | 'Handoffs'
  | 'Help'
  | 'Knowledge Base'
  | 'Phone Numbers'
  | 'Pronunciations'
  | 'Rules'
  | 'Secrets'
  | 'Settings'
  | 'SMS'
  | 'Template Assistant'
  | 'Voice';

export type ActionModifier =
  | 'click'
  | 'select'
  | 'open'
  | 'close'
  | 'cancel'
  | 'edit'
  | 'reset'
  | 'create'
  | 'update'
  | 'delete'
  | 'publish'
  | 'use'
  | 'copy'
  | 'duplicate'
  | 'download';

// used if an action isn't contained to one log
export type ActionState = 'start' | 'complete';

export const getActionName = (
  userFlow: ActionUserFlow,
  type?: ActionModifier,
  subject?: string,
  state?: ActionState,
) => {
  const actionContext = [state, type, subject]
    .filter((item) => !!item)
    .join(' ');

  if (actionContext.length === 0) {
    return userFlow;
  }
  return `${userFlow}: ${actionContext}`;
};
