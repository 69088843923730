import { AssistantConfig, ContentFilterPrecision } from './types';

export const DEFAULT_CONTENT_FILTER: AssistantConfig['content_filter'] = {
  config: {
    violence: {
      is_active: true,
      precision: ContentFilterPrecision.MEDIUM,
    },
    hate: {
      is_active: true,
      precision: ContentFilterPrecision.MEDIUM,
    },
    sexual: {
      is_active: true,
      precision: ContentFilterPrecision.MEDIUM,
    },
    self_harm: {
      is_active: true,
      precision: ContentFilterPrecision.MEDIUM,
    },
  },
  type: 'AZURE',
};
